<template>
  <q-layout class="layout-blank bg-white">
    <q-page-container>
      <transition>
        <router-view name="default" />
      </transition>
    </q-page-container>
  </q-layout>
</template>

<script type="text/javascript">
export default {
  name: 'LayoutBlank'
}
</script>
